<template>
  <div class="article-wrapper">
    <article class="article scroll-progress-section" v-if="article">
      <h2 class="article__heading">{{ article.title }}</h2>
      <section class="article__content post-content" v-html="article.text"></section>
    </article>
    <div class="article-comments">
      <h2 class="article-comments__heading">Comments</h2>
      <disqus shortname="findsourcing" :identifier="`article${article.id}`"></disqus>
    </div>
  </div>
</template>

<script>
import { Disqus } from 'vue-disqus';

export default {
  components: { Disqus },
  load({ store, route }) {
    return store.knowledgebank?.load(route.params.category, route.params.slug).then((data) => {
      store.head.title = `FindSourcing – ${data.title}`;
      // TODO: Add custom head.description & head.image
    });
  },
  data() {
    return {};
  },
  computed: {
    article() {
      return this.$store.knowledgebank.all[this.$route.params.slug] || {};
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';

.article {
  &-wrapper {
    padding: math.div(2rem, 1.6) math.div(1.5rem, 1.6);
  }
  &__heading {
    font-weight: normal;
    font-size: math.div(2.4rem, 1.6);
    text-align: center;
    letter-spacing: 1.4px;
    margin: 0;
    @include tablet-min {
      font-size: math.div(4rem, 1.6);
      margin-top: math.div(3rem, 1.6);
    }
    @include tablet-landscape-min {
      width: math.div(90rem, 1.6);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-comments {
    max-width: math.div(73rem, 1.6);
    padding: math.div(2.5rem, 1.6) 0;
    margin: 0 auto;
    &__heading {
      font-size: math.div(1.8rem, 1.6);
      font-weight: normal;
      @include tablet-min {
        font-size: math.div(2.4rem, 1.6);
      }
    }
  }
}
// Disqus styles
#disqus_thread {
  a {
    color: $c-blue;
  }
}
</style>
