var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article-wrapper" }, [
    _vm.article
      ? _c("article", { staticClass: "article scroll-progress-section" }, [
          _c("h2", { staticClass: "article__heading" }, [
            _vm._v(_vm._s(_vm.article.title)),
          ]),
          _vm._v(" "),
          _c("section", {
            staticClass: "article__content post-content",
            domProps: { innerHTML: _vm._s(_vm.article.text) },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "article-comments" },
      [
        _c("h2", { staticClass: "article-comments__heading" }, [
          _vm._v("Comments"),
        ]),
        _vm._v(" "),
        _c("disqus", {
          attrs: {
            shortname: "findsourcing",
            identifier: "article" + _vm.article.id,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }